window.addEventListener('load', updateFontFitting)
window.addEventListener('resize', updateFontFitting)

function updateFontFitting () {

  document.querySelectorAll('.fit-font').forEach(textElement => {
    textElement.style.fontSize = '10px'

    adaptFontFitting(textElement, textElement => {
      const currentFontSize = parseFloat(getComputedStyle(textElement).fontSize)
      textElement.style.fontSize = `${currentFontSize + 1}px`

      const textRect = textElement.getBoundingClientRect()
      const parentRect = textElement.parentElement.getBoundingClientRect()

      // if (textRect.height < parentRect.height && textRect.width < parentRect.width) {
      if (textRect.height < parentRect.height && textRect.width < parentRect.width) {
        return false
        // const currentFontSize = parseFloat(getComputedStyle(textElement).fontSize)
        // textElement.style.fontSize = `${currentFontSize + 1}px`

        // return false
      }

      textElement.style.fontSize = `${currentFontSize}px`

      return true
    })
  })

  // document.querySelectorAll('.fit-font-height').forEach(textElement => {
  //   textElement.style.fontSize = '10px'

  //   adaptFontFitting(textElement, textElement => {
  //     const currentFontSize = parseFloat(getComputedStyle(textElement).fontSize)
  //     textElement.style.fontSize = `${currentFontSize + 1}px`

  //     const textRect = textElement.getBoundingClientRect()
  //     const parentRect = textElement.parentElement.getBoundingClientRect()

  //     if (textRect.height < parentRect.height) {
  //       return false
  //     }

  //     textElement.style.fontSize = `${currentFontSize}px`

  //     return true
  //   })
  // })

  document.querySelectorAll('.stretch-font').forEach(textElement => {
    if (!textElement.classList.contains('fit-font')) {
      textElement.style.fontSize = '20px'
    }

    textElement.style.scale = ''

    adaptFontFitting(textElement, textElement => {
      // return true
      const scale = getComputedStyle(textElement).scale.split(' ').map(i => parseFloat(i) || 1)
      if (scale.length === 1) scale.push(1)

      scale[0] += 0.01
      scale[1] += 0.1
      textElement.style.scale = scale.join(' ')

      const settled = [false, false]
      const textRect = textElement.getBoundingClientRect()
      const parentRect = textElement.parentElement.getBoundingClientRect()

      if (textRect.width > parentRect.width || scale[0] > 3) {
        scale[0] -= 0.01
        settled[0] = true
      }

      if (textRect.height > parentRect.height) {
        scale[1] -= 0.1
        settled[1] = true
      }

      textElement.style.scale = scale.join(' ')

      return settled[0] && settled[1]
    })
  })
}

function adaptFontFitting (textElement, fn) {
  const MAX_TRIES = 1500

  textElement.style.display = 'inline-block'

  for (let i = 0; i < MAX_TRIES; i++) {
    if (fn(textElement)) break
  }

  textElement.classList.add('font-settled')
}

function closeNav () {
  document.body.classList.remove('nav-active')
  document.body.classList.remove('nav-about-us-active')
  document.body.classList.remove('nav-events-active')
  document.body.classList.remove('nav-service-active')
}

document.querySelector('nav').addEventListener('click', () => {
  closeNav()
}, { capture: true })

document.querySelector('.nav-bg').addEventListener('click', () => {
  closeNav()
}, { capture: true })

document.querySelector('.nav-about-us').addEventListener('click', () => {
  document.body.classList.add('nav-active')
  document.body.classList.add('nav-about-us-active')
})

document.querySelector('.nav-events').addEventListener('click', () => {
  document.body.classList.add('nav-active')
  document.body.classList.add('nav-events-active')
})

document.querySelector('.nav-service').addEventListener('click', () => {
  document.body.classList.add('nav-active')
  document.body.classList.add('nav-service-active')
})

document.querySelectorAll('.toggle-page-filter-menu').forEach(togglePageFilterMenuElement => {
  togglePageFilterMenuElement.addEventListener('click', () => {
    document.body.classList.toggle('page-filter-menu-active')
  })
})

let scrollContainer = document.body.classList.contains('page-template-home') ? document.querySelector('.nav-events .events') : document.querySelector('.page-content')

document.addEventListener('scroll', () => {
  // if (document.body.classList.contains('nav-active')) return
  scrollContainer.scrollLeft = document.body.scrollTop
})

// document.body.style.height = '3000px'

// add .nav-mobile-hidden after a timeout
setTimeout(() => {
  document.body.classList.add('nav-mobile-hidden')
  setTimeout(updateFontFitting, 500)
}, 800)

// const pageContentElement = document.querySelector('.page-content')
// const firstProjectElement = document.querySelector('.projects .project')
const scrollElement = document.querySelector('.page-template-projects') || document.querySelector('.page-template-home')
if (scrollElement) {
  scrollElement.style.height = `${scrollContainer.scrollWidth+500}px` // firstProjectElement.clientWidth
}
